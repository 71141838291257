  import {
      makeResponsive,
      placeTitle,
      placeImage,
      placeInput,
      placeSlider,
      hoverMe,
      placeRec,
      hiddenPt,
      fixedPt,
      clearInputFields,
      dragMe,
      placeArrow,
      placeGravity,
      placeText,
      placeLine,
      placePoint,
      placeGlider,
      placeRuler,
      placeLeftText,
      placeCircle,
      placeAngle,
      drawDash,
      placeLabel,
      drawPoint,
      drawLine,
      drawSegment,
      placeGo,
      placeStartOver,
      placeShuffle,
      placeLogo,
      alert,
      writeMiddleText
  } from '../Utils';
  const Boxes = {
    box1: function () {
      var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [0, 13, 16, -3],
          keepaspectratio: true, axis:false, ticks:{visible:false},
          grid:true, showCopyright:false, showNavigation:false,
          pan:{enabled:false}, zoom:{enabled:false}});
      brd1.options.layer['line'] =2;
      brd1.options.layer['polygon'] =2;
      brd1.options.layer['image'] =8;
      brd1.options.board.minimizeReflow = 'none';
      brd1.options.text.highlight =false;
      brd1.options.text.fixed =true;
      brd1.options.text.highlight =false;
      brd1.options.text.cssStyle='fontFamily:Oswald',
      brd1.options.image.highlight =false;
      brd1.options.polygon.highlight =false;
      brd1.options.line.highlight =false;
      brd1.options.point.highlight =false;
      brd1.options.curve.highlight =false;
      brd1.options.slider.highlight =false;
      brd1.options.point.showInfoBox =false;
      //TOP FLOOR
      var base =brd1.create('polygon',[[-2, 10],[20,10],[20, 13.0],[-2, 13.0]], {vertices:{visible:false}, withLines:false, fillColor:'black', fixed:true});
      placeLogo(brd1);
      makeResponsive(brd1);
      placeTitle(brd1, 'System of Springs', '(Combine Springs and Calculate Equivalent Spring Constant)');
      var imgOffsetX = 0.7;
      var imgOffsetY = 0.0;
      //message
      var msg =writeMiddleText(brd1, 8, 8, 'No spring attached!');
      msg.setAttribute({color:'red', visible:false});
      //SPRING CONSTANTS
      var txt3 = brd1.create('text', [3, -0.5, 'N/m'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      brd1.create('text', [0.5, -0.5, 'K_1 = '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      var txt4 = brd1.create('text', [7, -0.5, 'N/m'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      brd1.create('text', [4.5, -0.5, 'K_2 = '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      var txt5 = brd1.create('text', [11, -0.5, 'N/m'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      brd1.create('text', [8.5, -0.5, 'K_3 = '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      var txt6 = brd1.create('text', [15, -0.5, 'N/m'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      brd1.create('text', [12.5, -0.5, 'K_4 = '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
      //
      var Nm1 = placeInput(brd1, 1, -0.5, 5);
      Nm1.setAttribute({cssStyle: 'background-color:#2D42FD;'})
      var Nm2 = placeInput(brd1, 5, -0.5, 10);
      Nm2.setAttribute({cssStyle: 'background-color:#41C63C;'})
      var Nm3 = placeInput(brd1, 9, -0.5, 15);
      Nm3.setAttribute({cssStyle: 'background-color:#ECCC00;'})
      var Nm4 = placeInput(brd1, 13, -0.5, 20);
      Nm4.setAttribute({cssStyle: 'background-color:#FD2D2D;'})
      var segmentDragMe = brd1.create('segment', [[12.5, 7], [15,7]], {fixed: true, strokeColor: 'black', visible: false});
//
      var pDragMe = brd1.create('point', [8 , 6],{face:'o' , name:'pp', size:4, strokeColor: 'black', fillColor:'orange', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
// Hook Images
      var hook = brd1.create('image', ['/assets/hook.svg', [function(){return pDragMe.X()-1}, function(){return pDragMe.Y() - 2}], [2, 2]], {visible: true, fixed: false});
      //Mass image
      var massImg1 = brd1.create('image', ['/assets/1-kg.svg', [function(){return pDragMe.X()-1}, function(){return pDragMe.Y() - 3.5}], [2, 2]], {visible: true, fixed: false});
      //Atractors
      var lineAtt1 = brd1.create('segment', [[4.3, 9], [4.3, 5]], {visible:false, fixed: true, strokeColor: 'red', strokeWidth: 1, dash: 1});
      var lineAtt2 = brd1.create('segment', [[6.3, 9], [6.3, 5]], {visible: false, fixed: true, strokeColor: 'red', strokeWidth: 1, dash: 1});
      var lineAtt3 = brd1.create('segment', [[8.3, 9], [8.3, 5]], {visible: false, fixed: true, strokeColor: 'red', strokeWidth: 1, dash: 1});
      var lineAtt4 = brd1.create('segment', [[10.3, 9], [10.3,5]], {visible: false, fixed: true, strokeColor: 'red', strokeWidth: 1, dash: 1});
      //Images
      var str1 = brd1.create('image', ['/assets/springBlue.svg', [1.25, 0.15], [2., 2.]], {fillOpacity:1, attractors: [ lineAtt1, lineAtt2, lineAtt3, lineAtt4], attractorDistance:0.2, snatchDistance: 2});
      var str2 = brd1.create('image', ['/assets/springGreen.svg', [5.25, 0.15], [2., 2.]], {fillOpacity:1,attractors: [ lineAtt1, lineAtt2, lineAtt3, lineAtt4], attractorDistance:0.2, snatchDistance: 2, visible: true, fixed: false, rotate: 0});
      var str3 = brd1.create('image', ['/assets/springYellow.svg', [9.25, 0.15], [2., 2.]], {fillOpacity:1,attractors: [ lineAtt1, lineAtt2, lineAtt3, lineAtt4], attractorDistance:0.2, snatchDistance: 2, visible: true, fixed: false, rotate: 0});
      var str4 = brd1.create('image', ['/assets/springRed.svg', [13.25, 0.15], [2., 2.]], {fillOpacity:1,attractors: [ lineAtt1, lineAtt2, lineAtt3, lineAtt4], attractorDistance:0.2, snatchDistance: 2, visible: true, fixed: false, rotate: 0});
      //ORANGE WALLS
      var Pt1 = drawPoint(brd1,4, function(){return pDragMe.Y();})
      Pt1.setAttribute({visible:false});
      var Pt2 = drawPoint(brd1,12, function(){return pDragMe.Y();})
      Pt2.setAttribute({visible:false});
      var segment1 = drawSegment(brd1, Pt1, Pt2);
      segment1.setAttribute({strokeWidth:10});
      var Pt3 = drawPoint(brd1,4, 6);
      Pt3.setAttribute({visible:false});
      var Pt4 = drawPoint(brd1,12, 6);
      Pt4.setAttribute({visible:false});
      drawDash(brd1, Pt3, Pt4);
      // Segments
      var vert1 = brd1.create('segment', [[function(){if(str1.Y()>5 && str1.Y()<12 && str1.X()<=12 && str1.X()>=3.5){return str1.X() + imgOffsetX} else{return 5}}, 10], [function(){if(str1.Y()>5 && str1.Y()<12 && str1.X()<=12 && str1.X()>=3.5){return str1.X() + imgOffsetX} else{return 5}} ,function(){if(str1.Y()>5 && str1.Y()<12 && str1.X()<=12 && str1.X()>=3.5){return pDragMe.Y()} else{return 10}}]], {fixed: true, strokeColor: 'grey', strokeWidth: 1});
      //
      var vert2 = brd1.create('segment', [[function(){if(str2.Y()>5 && str2.Y()<12 && str2.X()<=12 && str2.X()>=3.5){return str2.X() + imgOffsetX} else{return 5}}, 10], [function(){if(str2.Y()>5 && str2.Y()<12 && str2.X()<=12 && str2.X()>=3.5){return str2.X() + imgOffsetX} else{return 5}} ,function(){if(str2.Y()>5 && str2.Y()<12 && str2.X()<=12 && str2.X()>=3.5){return pDragMe.Y()} else{return 10}}]], {fixed: true, strokeColor: 'grey', strokeWidth: 1});
//
      var vert3 = brd1.create('segment', [[function(){if(str3.Y()>5 && str3.Y()<12 && str3.X()<=12 && str3.X()>=3.5){return str3.X() + imgOffsetX} else{return 5}}, 10], [function(){if(str3.Y()>5 && str3.Y()<12 && str3.X()<=12 && str3.X()>=3.5){return str3.X() + imgOffsetX} else{return 5}} ,function(){if(str3.Y()>5 && str3.Y()<12 && str3.X()<=12 && str3.X()>=3.5){return pDragMe.Y()} else{return 10}}]], {fixed: true, strokeColor: 'grey', strokeWidth: 1});
//
      var vert4 = brd1.create('segment', [[function(){if(str4.Y()>5 && str4.Y()<12 && str4.X()<=12 && str4.X()>=3.5){return str4.X() + imgOffsetX} else{return 5}}, 10], [function(){if(str4.Y()>5 && str4.Y()<12 && str4.X()<=12 && str4.X()>=3.5){return str4.X() + imgOffsetX} else{return 5}} ,function(){if(str4.Y()>5 && str4.Y()<12 && str4.X()<=12 && str4.X()>=3.5){return pDragMe.Y()} else{return 10}}]], {fixed: true, strokeColor: 'grey', strokeWidth: 1});
      //Vectors
      var vectorString0 = [str1, str2, str3, str4];
      var vectorString1 = []; //Posiciones en el circuito
      var vectorNm1 = []; //Capacidades en el circuito
      var vectorKEqSerie = [];
      var vectoruNm0 = [];
      var arrowDeltaY = null;
      var txtDeltaY = null;
      //FUNCTIONS
      var txtKeq;
      var totalK=0;
      function kEqDeltaY(){
          vectoruNm0=[Nm1.Value(),Nm2.Value(),Nm3.Value(),Nm4.Value()];
          brd1.removeObject(txtKeq);
          str1.setLabel('');
          str2.setLabel('');
          str3.setLabel('');
          str4.setLabel('');
          vectorString1 = [];
          vectorNm1 = [];
          vectorKEqSerie = [];
          for (let i=0 ; i < vectorString0.length ; i = i +1 ){
              if( vectorString0[i].Y()>=pDragMe.Y()-0.25 && vectorString0[i].Y()<14 && vectorString0[i].X()<=12 && vectorString0[i].X()>=3.5 ){
                  vectorString1.push(vectorString0[i]);
                  vectorNm1.push(vectoruNm0[i]);
              }
          }
          for (let i=0 ; i < vectorString1.length ; i = i +1 ){
              if (vectorString1[i]!= null){
                  var kSerie=[vectorNm1[i]]
                  var kLabel = [vectorString1[i]]
                  for(var j=i+1; j< vectorString1.length; j += 1){
                      if( vectorString1[j] != null && vectorString1[i].X() == vectorString1[j].X() ){
                          kSerie.push(vectorNm1[j]);
                          kLabel.push(vectorString1[j]);
                          vectorString1[j]=null;
                      }
                  }
                  var kSerieEq = CalculateKSerial(kSerie, kLabel);
                  vectorKEqSerie.push(kSerieEq);
              }
          }
          if(vectorString1.length==0){alert(brd1, msg)}
          totalK=0;
          for (let i=0;i< vectorKEqSerie.length;i++){
              totalK = totalK + parseFloat(vectorKEqSerie[i]);
          }

          var force = 1*9.81;
          var deltaY = force/totalK;

          if(arrowDeltaY != null){
              brd1.removeObject(arrowDeltaY);
              arrowDeltaY = null;
          }

          if(txtDeltaY != null){
              brd1.removeObject(txtDeltaY);
              txtDeltaY = null;
          }

          if(deltaY != Infinity && !isNaN(deltaY) ){
              pDragMe.moveTo([8, 6-deltaY], 500);

              arrowDeltaY = brd1.create('segment', [[13, 6], [13, 6 - deltaY ]], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                  highlightStrokeWidth: 2,
                                                                                  lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                  touchLastPoint: false,
                                                                                  firstArrow: {type: 2, size: 8}
          });

              txtDeltaY = brd1.create('text', [14, 5.5 , '&delta; = ' + deltaY.toFixed(2)+' m'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
          }

      };

      var txt2 = brd1.create('text', [8, 10.5, ()=>' K_e_q =  '+totalK.toFixed(2)+' N/m'],{fixed: true, color:'blue', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});

      function CalculateKSerial (kSerie, kLabel){
          if (kSerie.length == 1){

              var q = (1*kSerie[0]).toFixed(2);
              return kSerie[0];

          }
          if (kSerie.length == 2){

              var q = (1*(1.0 / ( 1.0 /kSerie[0] + 1.0 / kSerie[1]))).toFixed(2);
              return (1.0 / ( 1.0 /kSerie[0] + 1.0 / kSerie[1]));
          }
          if (kSerie.length == 3){

              var q = (1*(1.0 / ( 1.0 /kSerie[0] + 1.0 / kSerie[1]  + 1.0 / kSerie[2]))).toFixed(2);
              return  (1.0 / ( 1.0 /kSerie[0] + 1.0 / kSerie[1]  + 1.0 / kSerie[2]))
          }
          if (kSerie.length == 4){

              var q = (1*(1.0 / ( 1.0 /kSerie[0] + 1.0 / kSerie[1] +  + 1.0 / kSerie[2] +  + 1.0 / kSerie[3]))).toFixed(2);
              return (1.0 / ( 1.0 /kSerie[0] + 1.0 / kSerie[1] +  + 1.0 / kSerie[2] +  + 1.0 / kSerie[3]));
          }
          return 0;

      }
      //Try Me Button
      var tryMe = placeGo(brd1,'left');
      var shuffle = placeShuffle(brd1, 'right');
      shuffle.on('down', function(){
                      str1.moveTo([-0.25, -1.25]);
                      str2.moveTo([3.75, -1.25]);
                      str3.moveTo([7.75, -1.25]);
                      str4.moveTo([11.75, -1.25]);
                      pDragMe.moveTo([8, 6], 500);
                      totalK=0;
                      //kEqDeltaY();
                    });
//
      tryMe.on('down', kEqDeltaY);
    //  tryMe.setLabel('Try Me')
    //  tryMe.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
    //  tryMe.on('over', function () {this.label.setAttribute({visible:true});});
    //  tryMe.on('out', function () {this.label.setAttribute({visible:false});});
      },
}
export default Boxes;
