<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> System of Springs</span>
      </div>
      <div ref="ia" style="margin-top: 20px" />
      <span class="subhead">A</span> system of springs is a group of springs connected together to form a single entity. The system behaves as a whole, and its overall behavior is a result of the interaction of individual springs. Each spring is affected by the forces acting on its neighboring springs, which results in the transfer of energy from one spring to another. The springs in a system of springs can be connected in series, in parallel or a combination of both.
      <div style="margin-top: 10px" />
      <v-layout align-center
                justify-center
                row
                wrap
      >
        <v-flex xs12
                sm8
                md8
                lg8
                xl8
                class="font-weight-light"
                :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          <div class="font-weight-medium"
               :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
          >
            <span style="font-family:Oswald"> Springs Connected in Parallel</span>
          </div>
          <div ref="ib" style="margin-top: 20px" />
          Two springs are connected in parallel if they are placed next to each other (i.e. side-by-side)  &mdash; as shown in the figure on the right. <br><br>
          Imagine a system of \(n\) springs that are connected in parallel. When a force is applied to a system of springs connected in parallel, the force applied on each individual spring is different. However, the total displacement of the system \(x\) is the same as the displacement of any individual spring. <br><br>
          The force applied on \(1^{st}\) spring is given by
          $$F_1 = k_1 x$$
          The force applied on \(2^{nd}\) spring is given by
          $$F_2 = k_2 x$$
          ...<br><br>
          Similarly, the force applied on \(n^{th}\) spring is given by
          $$F_n = k_n x$$
          The total force exerted by the system \(F\) is equal to the sum of the forces exerted by each spring.
          $$F = k_1x + k_2x + ... + k_nx = k_\text{eq}x$$
          where \(k_\text{eq}\) is the equivalent spring constant. Thus, the equivalent spring constant is given by
          $$ k_\text{eq} = k_1 + k_2 + ... + k_n $$
          <br><br>
          In other words, the equivalent spring constant of the system \(k\) is equal to the sum of the individual spring constants \(k_1\), \(k_2\), ...,\(k_n\).
        </v-flex>
        <v-flex xs12
                sm4
                md4
                lg4
                xl4
        >
          <v-img class="pa-2"
                 max-height="500px"
                 max-width="500px"
                 src="/assets/Parallel_Springs.jpg"
                 contain
          />
          <h5 style="text-align:center">
            A system of springs with springs connected in parallel.
          </h5>
          <br>
          <v-img class="pa-2"
                 max-height="500px"
                 max-width="500px"
                 src="/assets/Parallel_Connection_full.jpg"
                 contain
          />
          <h5 style="text-align:center">
            A system of springs can be replaced by a single spring with an equivalent spring constant.
          </h5>
        </v-flex>
      </v-layout>
      <br>
      <v-layout align-center
                justify-center
                row
                wrap
      >
        <v-flex xs12
                sm8
                md8
                lg8
                xl8
                class="font-weight-light"
                :class="{'headline': $vuetify.breakpoint.lgAndUp,'subtitle-1': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          <div class="font-weight-medium"
               :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
          >
            <span style="font-family:Oswald"> Springs Connected in Series</span>
          </div>
          <div ref="ic" style="margin-top: 20px" />
          Two springs are connected in series if they are placed one after the other &mdash; as shown in the figure on the right.<br><br>
          Imagine a system of \(n\) springs that are connected in series. When a force \(F\) is applied to a system of springs connected in series, the same force is applied to each individual spring. However, since the springs have different spring constants, each individual spring experiences different displacement.<br><br>
          The displacement of the \(1^\text{st}\) spring is
          $$x_1 = \frac{F}{k_1}$$
          The displacement of the  \(2^\text{nd}\) spring is
          $$x_2 = \frac{F}{k_2}$$
          ... <br><br>
          The displacement of the \(n^\text{th}\) spring is
          $$x_n = \frac{F}{k_n}$$
          The total displacement of the system \(x\) is the sum of displacements in each individual spring, i.e.
          $$x = \frac{F}{k_1} + \frac{F}{k_2} + ... + \frac{F}{k_n} = \frac{F}{k_\text{eq}}$$
          <br><br>
          where \(k_{eq}\) is the equivalent spring constant. Thus, the equivalent spring constant is given by
          $$\frac{1}{k_\text{eq}} = \frac{1}{k_1} + \frac{1}{k_2} + ... + \frac{1}{k_n}$$
        </v-flex>
        <v-flex xs12
                sm4
                md4
                lg4
                xl4
        >
          <v-img class="imgC"
                 padding="5px"
                 max-height="500px"
                 max-width="500px"
                 src="/assets/Series_Springs.jpg"
                 contain
          />
          <h5 style="text-align:center">
            A system of springs with springs connected in parallel.
          </h5>
          <br>
          <v-img class="imgC"
                 padding="5px"
                 max-height="500px"
                 max-width="500px"
                 src="/assets/Parallel_Connection_full.jpg"
                 contain
          />
          <h5 style="text-align:center">
            A system of springs can be replaced by a single spring with an equivalent spring constant.
          </h5>
        </v-flex>
      </v-layout>

      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Learn interactively | System of Springs </span>
      </div>
      <div ref="id" style="margin-top: 20px" />
      Through this module, students will learn to build a system of springs by combining multiple springs in different ways and (b) calculate the equivalent spring constant of the system.
      <div style="margin-top: 10px" />
      <h5> Session Objectives: </h5>
      <div style="margin-top: 10px" />
      You are given a set of four springs as shown in the MagicGraph.
      The goal is to build a system of springs by combining these springs in various ways like in series, in parallel or a combination of the two.
      <div style="margin-top: 10px" />
      <h5> Getting Started: </h5>
      Enter the spring constants for springs. Drag and attach springs to build a system of springs. Tap on
      <i class="goer ma-1" /> to submit and calculate the equivalent spring constant of the system.  <br>
    </div>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          You can move the springs around and attach them in different configurations. Once done, tap on <i class="goer ma-1" /> on to calculate the effective spring constant and the resulting deflection of the system.  Tap on <i class="shuffler ma-1" /> to reset.
        </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'SOS',
  created: function () {
    // Store mutations
    let title = 'System of Springs';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'System of Springs', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Parallel Connection', img:'/assets/number-2.svg', action: () => this.goto('ib')},
      {title: 'Series Connection', img:'/assets/number-3.svg', action: () => this.goto('ic')},
      {title: 'Interactive Learning' ,img:'/assets/touch.svg', action: () => this.goto('id')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'System of Springs',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
